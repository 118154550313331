<template>
  <div id="invoice-layout">
    <KSubMenu
      :menu-items="menuItems"
      :parent-menu="parentMenu"
    />
    <RouterView ref="view" />
  </div>
</template>

<script>
import KSubMenu from '@/components/menu/KSubMenu.vue';

export default {
  name: 'InvoiceLayout',
  components: { KSubMenu },
  computed: {
    menuItems() {
      return [
        {
          title: this.$t('invoice.titleOverview'),
          route: { name: 'invoice.concept.index' },
        },
        {
          title: this.$t('invoice.show', { resource: this.$tc('invoice.title', 1) }),
          route: { name: 'invoice.index' },
        },
        {
          title: this.$tc('invoice.externalInvoices.title', 2),
          route: { name: 'invoice.externalInvoices.index' },
        },
      ];
    },
    parentMenu() {
      return [
        {
          title: this.$tc('invoice.title', 2),
          route: { name: 'dashboard' },
        },
      ];
    },
  },
};
</script>
